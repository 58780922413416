<template>
  <v-autocomplete
    v-model="lookId"
    :label="inputLabel($t('views.products.forms.input.look'), required)"
    :items="taxonomies.look"
    :error-messages="error"
    hide-details
    clearable
    dense
    outlined
    item-text="name"
    item-value="id"
    @change="$emit('update:error', null)"
  />
</template>

<script>

import inputMixin from '@/mixins/input'
import { mapState } from 'vuex'

export default {
  mixins: [ inputMixin ],
  props: {
    value: {
      type: String,
      required: false,
      default: null,
    },
    error: {
      type: String,
      required: false,
      default: null,
    },
    required: Boolean,
  },
  computed: {
    ...mapState(['taxonomies']),
    lookId: {
      get () { return this.value },
      set (value) { this.$emit('input', value) },
    },
  },
}
</script>
